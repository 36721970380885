import axios from "axios";
let formContact = document.getElementById('contactForm');

(!formContact) ? false :
    formContact.addEventListener('submit',(e) =>contact(e));

async function contact(e){
    e.preventDefault();
    let data = new FormData(formContact);
    let response = await axios.post('/contact',data);
    console.log(response.status);
    renderView();
}

function renderView(){
    let content = document.getElementById('contentForm');
    let data = '<div class="h-100 d-flex align-items-center justify-content-center"><h6 class="alert alert-success">Contato Enviado!</h6></div>';
    content.innerHTML = data;
}
