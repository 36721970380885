var playerAudio, btn, icon;

let player = document.getElementById('btn-player');

(player) ?
player.addEventListener('click',() => prepare(this)) : false;

console.log(process.env.STREAMING);
function prepare(e) {
    let playerAudio = document.querySelector('#audio');
    let btn = document.querySelector("#btn-player");
    let icon = document.querySelector("#play-pause");
    let text = document.querySelector("#text-play");

    if (playerAudio.played.length != 0) {
        if (playerAudio.played.start(0) == 0 && !playerAudio.paused) {
            playerAudio.pause();
            text.innerHTML = "OUÇA AO VIVO"
            icon.className = "bi bi-play-circle-fill";
            btn.classList.remove("active");
        } else {
            playerAudio.src = `${process.env.STREAMING};,'audio/mp4; codecs='mp4a.40.5'`;
            playerAudio.play();
            text.innerHTML = "OUVINDO AO VIVO"
            icon.className = "bi bi-stop-circle-fill";
            btn.classList.add("active");
        }
    } else {
        playerAudio.src = `${process.env.STREAMING};','audio/mp4; codecs='mp4a.40.5'`;
        playerAudio.play();
        text.innerHTML = "OUVINDO AO VIVO"
        icon.className = "bi bi-stop-circle-fill";
        btn.classList.add("active");
    }
}