import('../../styles/site/popup.css');

// Get the modal
var modal = document.getElementById("myModal");

// Get the image and insert it inside the modal - use its "alt" text as a caption
var img = document.querySelectorAll("#fotos");
var modalImg = document.getElementById("img01");
var captionText = document.getElementById("caption");
console.log(img);
img.item(0).onclick = function () {
    console.log(img);
    modal.style.display = "block";
    modalImg.src = this.src;
    captionText.innerHTML = this.alt;
}
img.item(1).onclick = function () {
    console.log(img);
    modal.style.display = "block";
    modalImg.src = this.src;
    captionText.innerHTML = this.alt;
}
img.item(2).onclick = function () {
    console.log(img);
    modal.style.display = "block";
    modalImg.src = this.src;
    captionText.innerHTML = this.alt;
}
// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks on <span> (x), close the modal
span.onclick = function () {
    modal.style.display = "none";
}