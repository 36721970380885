let AOS = require('aos');
import('aos/dist/aos.css');

AOS.init();
//Scroll
window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    var navbar = document.getElementById("navbar");
    let button = document.getElementById("btn-player");
    let windowWidth = window.innerWidth;
    let elCoordenadas = button.getBoundingClientRect();
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        navbar.classList.add("shadow");
        navbar.classList.add("bg-white");
        navbar.classList.remove("mt-4");
    } else {
        navbar.classList.remove("shadow");
    }
    if (document.body.scrollTop > 364 || document.documentElement.scrollTop > 364 || (windowWidth < 533 && document.documentElement.scrollTop > 160)){
        button.classList.add("fixed-action-btn");
    }else{
        button.classList.remove("fixed-action-btn");
    }
}
//Button
let windowWidth = window.innerWidth;

function verificaWidth() {
    if(windowWidth < 1400){
        button.classList.add("fixed-action-btn");
    }
    setTimeout("minha_funcao()", 1000);
}
//site
let myModal = document.getElementById('exampleModal1');
let myInput = document.getElementById('myInput');
(myModal) ?
myModal.addEventListener('shown.bs.modal', function () {
    myInput.focus()
}) : false;